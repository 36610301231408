import React, { useState } from 'react';
import greatCircleDistance from './greatCircleDistance';
import InputAutosuggestLocation from './InputAutosuggestLocation';

const MAX_GEO_DISTANCE = 109;

export default function useLocationFilteredEvents(items) {
  const [locationFilter, setLocationFilter] = useState();

  const filteredItems = locationFilter
    ? items
        .map(item => {
          const itemPos = item.eventVenue[0].eventVenueAddress;
          const distance = greatCircleDistance(
            locationFilter.lat,
            locationFilter.lon,
            itemPos.lat,
            itemPos.lng,
          );
          const approxDistance = Math.floor(distance / 10) * 10;
          const displayDistance = approxDistance
            ? `etwa ${approxDistance} km entfernt`
            : 'direkt vor der Tür';

          return {
            ...item,
            distance,
            approxDistance,
            displayDistance,
          };
        })
        .filter(({ distance }) => distance < MAX_GEO_DISTANCE)
        .sort((a, b) => a.distance - b.distance)
    : items;

  return [
    filteredItems,
    <InputAutosuggestLocation
      onChange={item => setLocationFilter(item?.payload)}
    />,
  ];
}
