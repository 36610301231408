import React from 'react';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { colors, fonts, sizes } from 'components/Layout/variables';

export const ShopStyled = styled('div')({
  display: 'block',
});

export const ItemContainerStyled = styled('div')({
  display: 'block',
  margin: `0 -${sizes.spacingHalf}`,
});

const SHOP_ITEMS_PER_ROW_XS = 1;
const SHOP_ITEMS_PER_ROW_SM = 2;
const SHOP_ITEMS_PER_ROW_LG = 3;
const SHOP_ITEMS_PER_ROW = 4;

export const ShopItemStyled = styled('div')({
  display: 'inline-block',
  margin: `${sizes.spacingQuarter} ${sizes.spacingHalf}`,
  width: `calc((100% / ${SHOP_ITEMS_PER_ROW_XS}) - ${sizes.spacing})`,

  [media('sm')]: {
    width: `calc((100% / ${SHOP_ITEMS_PER_ROW_SM}) - ${sizes.spacing})`,
  },

  [media('lg')]: {
    width: `calc((100% / ${SHOP_ITEMS_PER_ROW_LG}) - ${sizes.spacing})`,
  },

  [media('xl')]: {
    width: `calc((100% / ${SHOP_ITEMS_PER_ROW}) - ${sizes.spacing})`,
  },
});
