import { styled } from 'utils/emotion';
import { Row, Column } from 'components/Grid';

export const TeaserSectionStyled = styled('section')({
  display: 'block',

  'p:last-child': { marginBottom: 20 },
});

export const RowStyled = styled(Row)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
});

export const ColumnStyled = styled(Column)({ marginBottom: 50 });
