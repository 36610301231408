import React from 'react';
import { graphql } from 'gatsby';
import BlockquoteDefault from 'components/Blockquote';
import { parseAsContent } from 'utils/parser';

export default function Blockquote(props) {
  const { blockquoteCite, blockquoteThemeName } = props?.pageBuilder;
  const { content } = props?.pageBuilder?.blockquoteText;

  return (
    <BlockquoteDefault cite={blockquoteCite} theme={blockquoteThemeName}>
      {parseAsContent(content)}
    </BlockquoteDefault>
  );
}

export const query = graphql`
  fragment PageBuilderBlockquoteQuery on Craft_PageBuilderBlockquote {
    id
    blockquoteCite
    blockquoteThemeName
    blockquoteText {
      content
    }
  }
`;
