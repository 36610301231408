import React from 'react';
import { graphql } from 'gatsby';
import Headline from 'components/Headline';

/**
 * For now we allow H4 headlines only.
 */
export default function Subline(props) {
  const { headlineText, headlineTag } = props?.pageBuilder;

  return <Headline element={'h4'}>{headlineText}</Headline>;
}

export const query = graphql`
  fragment PageBuilderHeadlineQuery on Craft_PageBuilderHeadline {
    id
    headlineText
    headlineTag
  }
`;
