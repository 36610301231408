import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import LoadMoreContainer from 'components/LoadMoreContainer';
import Tile from './Tile';
import { ShopStyled, ShopItemStyled, ItemContainerStyled } from './styles';
import LinkButton from '../LinkButton/LinkButton';

export const Shop = (props) => {
  const { items } = props;

  return (
    <ShopStyled>
      <ItemContainerStyled>
        <LoadMoreContainer limit={6}>
          {items.map((item) => (
            <ShopItemStyled key={item.id}>
              <Tile {...item} />
            </ShopItemStyled>
          ))}
        </LoadMoreContainer>
      </ItemContainerStyled>
      <div>
        <LinkButton
          to="https://vielspass.gmbh/products"
          target="_blank"
          themeName="grey"
          // compact
        >
          zum Shop
        </LinkButton>
      </div>
    </ShopStyled>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ShopQuery {
        craft {
          items: entries(section: shop, orderBy: "postDate DESC") {
            id
            title
            postDate
            ... on Craft_ShopProduct {
              price: productPrice
              theme: productTheme
              category: productCategory
              link: productLink {
                link
                customText
                target
                text
                type
                url
              }
              image: productImage {
                id
                url
                width
                height
                title
                thumbSquare: url(transform: transformProductThumb)
              }
            }
          }
        }
      }
    `}
    render={(data) => <Shop items={data.craft.items} />}
  />
);
