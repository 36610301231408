import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'components/Layout/variables';
import { AvailabilityStyled } from './styles';

/**
 * Ticket availability.
 */
export const AVAILABILITIES = {
  available: {
    label: 'Tickets verfügbar',
    color: colors.green,
  },
  fewLeft: {
    label: 'Nur noch wenige Tickets verfügbar',
    color: colors.yellowDark,
  },
  soldOut: {
    label: 'Ausverkauft',
    color: colors.red,
  },
  noSale: {
    label: 'Zzt. nicht verfügbar',
    color: colors.yellowDark,
  },
  undefined: {
    label: 'Status unbekannt',
    color: colors.greyMedium,
  },
};

/**
 * Function:
 * Get availability for key.
 */
export const getAvailabilityForKey = key => {
  if (AVAILABILITIES[key]) {
    return AVAILABILITIES[key];
  }

  return AVAILABILITIES.undefined;
};

const Availability = ({ status, uppercase, small }) => {
  const availability = getAvailabilityForKey(status);

  return (
    <AvailabilityStyled
      hightlightColor={availability.color}
      uppercase={uppercase}
      small={small}
      style={{color: availability.color}}
    >
      {availability.label}
    </AvailabilityStyled>
  );
};

Availability.propTypes = {
  status: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
};

Availability.defaultProps = {
  uppercase: false,
};

export default Availability;
