import styled from 'react-emotion';
import { sizes } from 'components/Layout/variables';

/**
 * input icon label
 */
export const AutosuggestStyled = styled('div')({
  position: 'relative',
  display: 'flex',
  marginBottom: sizes.spacingHalfDouble,
});

/**
 * input icon label
 */
export const LabelStyled = styled('label')({
  flex: 'none',
  display: 'inline-block',
  height: 'auto',
  marginRight: sizes.spacingHalf,
  position: 'relative',
  top: 3,
});

/**
 * input icon label
 */
export const MenuStyled = styled('ul')({
  position: 'absolute',
  display: 'block',
  backgroundColor: 'white',
  listStyle: 'none',
  boxShadow:
    '2px 2px 7px rgba(0, 0, 0, 0.03), 3px 5px 25px rgba(0, 0, 0, 0.05)',
  padding: 0,
  margin: 0,
  zIndex: 100,
  top: 38,
  left: 19,
  borderRadius: sizes.radius,
  minWidth: 332,
  paddingTop: sizes.radius,
  paddingBottom: sizes.radius,
});

/**
 * input icon label
 */
export const MenuItemStyled = styled('li')({
  padding: `${sizes.spacingQuarter} ${sizes.spacingHalf}`,
  margin: 0,
});

/**
 * input icon label
 */
export const ActionButtonStyled = styled('button')({
  flex: 'none',
  position: 'relative',
  fontSize: 12,
  marginLeft: sizes.spacingQuarter,
});
