import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import Icon from 'components/Icon';
import { colors } from 'components/Layout/variables';
import InputText from '../InputText';
import {
  AutosuggestStyled,
  LabelStyled,
  MenuStyled,
  MenuItemStyled,
  ActionButtonStyled,
} from './styles';

function InputAutosuggest({ refine, hits, placeholder, label, onChange }) {
  return (
    <Downshift
      onChange={onChange}
      itemToString={item => (item ? item.label : '')}
    >
      {({
        getRootProps,
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
        clearSelection,
      }) => (
        <AutosuggestStyled {...getRootProps({ refKey: 'innerRef' })}>
          <LabelStyled {...getLabelProps()}>{label}</LabelStyled>
          <InputText
            {...getInputProps({
              onChange(e) {
                refine(e.target.value);
              },
            })}
            placeholder={placeholder}
            label
          />
          {Boolean(selectedItem) && (
            <ActionButtonStyled type="button" onClick={clearSelection}>
              <Icon name="cross" />
            </ActionButtonStyled>
          )}
          {isOpen && hits.length ? (
            <MenuStyled {...getMenuProps()}>
              {hits.map((item, index) => (
                <MenuItemStyled
                  {...getItemProps({
                    key: item.key || item.label,
                    index,
                    item,
                    style: {
                      backgroundColor:
                        highlightedIndex === index
                          ? colors.yellowFaded
                          : undefined,
                      fontWeight: selectedItem === item ? 'bold' : 'normal',
                    },
                  })}
                >
                  {item.label}
                </MenuItemStyled>
              ))}
            </MenuStyled>
          ) : null}
        </AutosuggestStyled>
      )}
    </Downshift>
  );
}

InputAutosuggest.propTypes = {
  refine: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string,
      payload: PropTypes.any,
    }),
  ),
  placeholder: PropTypes.string,
  icon: PropTypes.node,
};

InputAutosuggest.defaultProps = {
  items: [],
  placeholder: '',
  icon: null,
};

export default InputAutosuggest;
