import React from 'react';
import InputAutosuggest from 'components/Forms/InputAutosuggest';
import Icon from 'components/Icon';
import useLocationGeocode from './useLocationGeocode';

export const InputAutosuggestLocation = ({ onChange }) => {
  const [hits, handleRefine] = useLocationGeocode();

  return (
    <InputAutosuggest
      label={<Icon name="magnifier" />}
      placeholder="Termin in Ihrer Nähe finden"
      onChange={onChange}
      refine={handleRefine}
      hits={hits}
    />
  );
};

export default InputAutosuggestLocation;
