import React from 'react';
import styled from 'react-emotion';

export const VideosStyled = styled('div')({
  display: 'block',
});

export const VideoItemStyled = styled('div')({
  marginBottom: 40,
});
