import React from 'react';
import { graphql } from 'gatsby';
import { parseAsContent } from 'utils/parser';

export default function Text(props) {
  const { content } = props?.pageBuilder?.textContent;

  // Returns set of elements matching the parser definition.
  return parseAsContent(content);
}

export const query = graphql`
  fragment PageBuilderTextQuery on Craft_PageBuilderText {
    id
    textContent {
      content
    }
  }
`;
