import styled from 'react-emotion';

export const PictureStyled = styled('picture')({
  position: 'relative',
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
});

export const ImgStyled = styled('img')({
  // Fun fact: https://stackoverflow.com/questions/10844205/html-5-strange-img-always-adds-3px-margin-at-bottom
  verticalAlign: 'middle',
});
