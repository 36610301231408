import React from 'react';
import styled from 'react-emotion';
import Headline from 'components/Headline';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import { colors, fonts, sizes } from 'components/Layout/variables';

/**
 * Tile Link.
 */
export const TileLinkStyled = styled(Link)({
  position: 'relative',
  marginBottom: sizes.spacingHalf,
  borderRadius: sizes.radius,
  width: '100%',
  display: 'flex',
  overflow: 'hidden',

  '&::after': {
    content: '""',
    display: 'table',
    clear: 'both',
  },

  '&:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});

/**
 * Image.
 */
export const ImageWrapperStyled = styled('div')({
  flex: 'none',
  position: 'relative',
  borderRadius: sizes.radius,
  overflow: 'hidden',
  width: '100%',
});

/**
 * Inner tile.
 */
export const TileInnerStyled = styled('div')({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'start',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  padding: `${sizes.spacingHalf} ${sizes.spacingQuarters}`,
  borderRadius: sizes.radius,

  '&:before': {
    content: '""',
    background:
      'linear-gradient(180deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,.6) 100%)',
    borderRadius: sizes.radius,
    transition: '.15s ease opacity',
    position: 'absolute',
    zIndex: -1,
    left: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
  },

  [media('xl')]: {
    padding: sizes.spacingHalf,

    [`${TileLinkStyled}:hover &:before`]: {
      opacity: 0.65,
    },
  },
});

export const CategoryStyled = styled('span')({
  fontWeight: 'bold',
  fontSize: '.8em',
  color: colors.greyLight,
  opacity: 0.8,
  marginRight: sizes.spacingDouble,
});

export const TitleStyled = styled('div')({
  fontWeight: 'bold',
  lineHeight: 1.2,
  color: colors.white,
  width: '100%',
  flex: 'none',
  overflow: 'hidden',
  margin: `4px 0 ${sizes.spacingQuarter}`,

  [media('sm')]: {
    marginTop: 0,
    marginBottom: sizes.spacingHalf,
  },
});

export const LabelStyled = styled('div')({
  fontSize: 9,
  fontWeight: 'bold',
  letterSpacing: '.1em',
  lineHeight: 1,
  color: colors.greyDark,
  textTransform: 'uppercase',
  borderRadius: 4,
  flex: 'none',
  alignSelf: 'flex-start',
  padding: '4px 7px 3px',
  background: colors.yellow,
  width: 'auto',
});
