import styled from 'react-emotion';
import { sizes } from 'components/Layout/variables';
import { Row } from 'components/Grid';

/**
 * Images.
 */
export const ImagesStyled = styled('div')({
  width: '100%',
  height: 'auto',
  display: 'block',
  marginBottom: sizes.spacingHalfDouble,
});

export const RowStyled = styled(Row)({
  alignItems: 'baseline',
});
