import React from 'react';
import styled from 'react-emotion';
import Link from 'components/Link';
import LinkButton from 'components/LinkButton';
import media from 'utils/mediaqueries';
import { colors, sizes, fonts } from 'components/Layout/variables';

/**
 * Tile.
 */
export const TileStyled = styled('div')(
  {
    backgroundColor: '#fff',
    marginBottom: sizes.spacingHalf,
    padding: sizes.spacing,
    borderLeft: `${sizes.spacingQuarter} solid #c0c0c0`,
    borderRadius: sizes.radius,
    lineHeight: 1.5,

    [media('md')]: {
      borderWidth: sizes.spacingHalf,
    },

    [media('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  props => ({
    borderColor: props.hightlightColor,
  }),
);

/**
 * Wrapper.
 * Giving the inner sections some form.
 */
export const WrapperStyled = styled('div')({
  marginBottom: sizes.spacing,
  flex: 'none',

  [media('lg')]: {
    margin: `0 ${sizes.spacingHalf}`,
  },

  '&:last-child': {
    marginBottom: 0,
  },
});

export const WrapperLeftStyled = styled(WrapperStyled)({
  [media('lg')]: {
    width: '43%',
  },
  [media('xl')]: {
    width: '50%',
  },
});

export const WrapperMiddleStyled = styled(WrapperStyled)({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
});

export const WrapperRightStyled = styled(WrapperStyled)({
  [media('md')]: {
    width: '170px',
  },
  [media('lg')]: {
    textAlign: 'center',
  },
});

export const WrapperDetailsStyled = styled('div')({
  flex: 'none',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1.3,
});

export const WrapperVenueStyled = styled('div')({});

export const WrapperTicketsStyled = styled('div')({});

/**
 * Date.
 */
export const DateStyled = styled('div')({
  display: 'block',
  lineHeight: 1.1,
  fontWeight: 700,
  fontSize: 30,
  width: 40,
  marginRight: sizes.spacing,
  textAlign: 'center',

  [media('xl')]: {
    marginRight: sizes.spacingDouble,
  },
});

export const DayStyled = styled('span')({
  display: 'block',
});

export const MonthStyled = styled('span')({
  display: 'block',
  textTransform: 'uppercase',
  fontSize: '.44em',
  letterSpacing: '.16em',
});

export const TimeStyled = styled('span')({
  display: 'block',
  textTransform: 'uppercase',
  fontSize: '.35em',
  letterSpacing: '.16em',
  lineHeight: 1.5,
});

export const YearStyled = styled('span')({
  display: 'block',
  fontSize: '.5em',
});

/**
 * Title.
 */
export const TitleStyled = styled('div')({
  fontWeight: '700',
});

/**
 * Venue.
 */

export const VenueStyled = styled('div')({
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
});

export const VenueLinkStyled = styled(Link)({
  fontWeight: '700',
  textDecoration: 'none',
  WebkitTapHighlightColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
});

export const VenueTitleStyled = styled('div')({
  fontWeight: '700',
});

export const VenueCityStyled = styled('div')({
  fontSize: fonts.sizes.small,
  color: colors.greyMedium,
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
});

export const VenueFlagStyled = styled('div')({
  flex: 'none',
  display: 'inline-flex',
  fontSize: '1.3em',
  textAlign: 'right',
  margin: `0 ${sizes.spacingHalf} 0 0`,
  // margin: `0 ${sizes.spacingHalf} 0 ${sizes.spacing}`,
});

/**
 * Tickets.
 */
export const TicketsStyled = styled('div')({
  textAlign: 'left',
  width: '100%',

  [media('md')]: {
    textAlign: 'center',
  },
});

export const TicketButtonStyled = styled(LinkButton)({
  display: 'block',
  textAlign: 'center',

  [media('md')]: {
    display: 'inline-block',
  },
});

/**
 * Availability.
 */
export const AvailabilityStyled = styled('span')(
  {
    display: 'inline-block',
    fontWeight: '700',
  },
  props => ({
    color: props.hightlightColor,
    fontSize: props.small ? '.8em' : '.9em',
    textTransform: props.uppercase ? 'uppercase' : 'normal',
  }),
);
