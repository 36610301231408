import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import {
  VenueStyled,
  VenueLinkStyled,
  VenueCityStyled,
  VenueTitleStyled,
  VenueFlagStyled,
} from './styles';

const getFlagIconForCountry = country => {
  switch (country.toLowerCase()) {
    case 'germany':
    case 'deutschland':
      return <Icon name="flag_de" />;
    case 'österreich':
    case 'austria':
      return <Icon name="flag_at" />;
    case 'schweiz':
    case 'swiss':
    case 'switzerland':
      return <Icon name="flag_ch" />;
    case 'liechtenstein':
      return <Icon name="flag_lie" />;
    case 'luxemburg':
      return <Icon name="flag_lux" />;
    default:
      return null;
  }
};

const Venue = ({ title, eventVenueAddress, eventVenueLink }) => {
  const eventVenueFlag = getFlagIconForCountry(eventVenueAddress.country);

  const content = (
    <>
      {eventVenueFlag && <VenueFlagStyled>{eventVenueFlag}</VenueFlagStyled>}
      <div>
        <VenueTitleStyled>{title}</VenueTitleStyled>
        {eventVenueAddress?.city && (
          <VenueCityStyled>{eventVenueAddress.city}</VenueCityStyled>
        )}
      </div>
    </>
  );

  if (eventVenueLink?.url) {
    return (
      <VenueLinkStyled to={eventVenueLink.url} target={eventVenueLink.target}>
        {content}
      </VenueLinkStyled>
    );
  }

  return <VenueStyled>{content}</VenueStyled>;
};

Venue.propTypes = {
  title: PropTypes.string.isRequired,
  eventVenueAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    street1: PropTypes.string,
    zip: PropTypes.string,
  }),
  eventVenueLink: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Venue.defaultProps = {
  eventVenueAddress: null,
  eventVenueLink: null,
};

export default Venue;
