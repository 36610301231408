import React from 'react';
import PropTypes from 'prop-types';
import { ImageWrapperStyled, ImageStyled, ImageTitle } from './styles';

/**
 * rs: this component has optimization potential
 * we could use the default Image component.
 */
const Tile = ({ image, imageTitle, columnCount }) => {
  const imgHeight = image[0].height;

  // Prevent upscaled image versions, when original image (height) is smaller
  const thumbLarge = !!(imgHeight >= 840);

  // Set max height for full (100%) or half (50%) column
  let maxHeight;
  maxHeight = columnCount === 1 && 540;
  maxHeight = columnCount === 2 && 360;

  return (
    <>
      <ImageWrapperStyled>
        <ImageStyled maxHeight={maxHeight}>
          {thumbLarge && (
            <source
              srcSet={encodeURI(image[0].urlFull)}
              media="(min-width: 768px)"
            />
          )}
          <img src={encodeURI(image[0].urlMedium)} alt={image[0].title || ''} />
        </ImageStyled>
      </ImageWrapperStyled>
      <ImageTitle>{imageTitle}</ImageTitle>
    </>
  );
};

Tile.propTypes = {};

Tile.defaultProps = {};

export default Tile;
