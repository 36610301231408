import React from 'react';
import { graphql } from 'gatsby';

import Headline from 'components/Headline';
import Text from 'components/Text';
import LinkButton from 'components/LinkButton';
import Wrapper from 'components/Wrapper';
import SwitchTheme from 'components/SwitchTheme';

import { parseAsContent } from 'utils/parser';

import {
  HeroStyled,
  ContentStyled,
  ContentTextStyled,
  BgImgWrapper,
  ImgWrapper,
} from './styles';

const Hero = (props) => {
  const {
    heroText,
    heroTitle,
    heroLinks,
    heroImage,
    heroBgImage,
    heroBgImageSpacing,
    heroThemeName,
  } = props?.pageBuilder;

  // console.log(heroLinks);

  return (
    <SwitchTheme name={heroThemeName}>
      <HeroStyled>
        {heroBgImage[0] ? (
          <BgImgWrapper
            backgroundImage={heroBgImage[0].urlOptimized}
            hasSpacing={heroBgImageSpacing}
          />
        ) : (
          <Headline>{heroTitle}</Headline>
        )}
        <Wrapper>
          <ContentStyled>
            {heroImage &&
              heroImage.map((image) => (
                <ImgWrapper>
                  <img
                    src={image.url}
                    alt={image.title}
                    width={image.width}
                    height={image.height}
                  />
                </ImgWrapper>
              ))}
            <ContentTextStyled>
              {heroText?.content && (
                <Text element="div">{parseAsContent(heroText.content)}</Text>
              )}
              {heroLinks?.length > 0 &&
                heroLinks.map((item) => {
                  return (
                    <LinkButton
                      compact
                      to={
                        item.heroLink.entry?.slug
                          ? `/#${item.heroLink.entry?.slug}`
                          : item.heroLink.url
                      }
                      themeName={item.heroButtonTheme}
                      key={`herolink-${item.id}`}
                    >
                      {item.heroLink.text}
                    </LinkButton>
                  );
                })}
            </ContentTextStyled>
          </ContentStyled>
        </Wrapper>
      </HeroStyled>
    </SwitchTheme>
  );
};

export default Hero;

export const query = graphql`
  fragment PageBuilderHeroQuery on Craft_PageBuilderHero {
    id
    heroThemeName
    heroImage {
      id
      url
      title
      width
      height
    }
    heroTitle
    heroText {
      content
    }
    heroLinks {
      id
      heroButtonTheme
      heroLink {
        text
        url
        entry {
          slug
        }
      }
    }
    heroBgImage {
      id
      title
      urlOptimized: url(crop: { width: 1920, quality: 95 })
    }
    heroBgImageSpacing
  }
`;
