import React from 'react';
import PropTypes from 'prop-types';
import { breakpoints } from 'components/Layout/variables';
import { PictureStyled, ImgStyled } from './styles';

/**
 * Simple image compontent that accepts a
 * set of breakpoints for responsive images.
 *
 * Usage:
 * <Image
 *   src="my_img_url"
 *   alt="my_img_alt_text"
 *   srcSet={{ sm: 'my_sm_img_url', lg: 'my_lg_img_url' }}
 * />
 */
const Image = ({ id, src, alt, srcSet, className }) => {
  let additionalSources = [];

  if (srcSet) {
    // Match srcSet to breakpoints as additional sources.
    additionalSources = Object.keys(srcSet).map(breakpoint => ({
      key: `source-${id}-${breakpoint}`,
      minWidth: breakpoints[breakpoint] || breakpoint,
      src: srcSet[breakpoint],
    }));

    // Sort source items.
    // If the sources aren't in the right order the images will be shown wrong.
    additionalSources = additionalSources.sort((firstSource, secondSource) => {
      if (firstSource.minWidth > secondSource.minWidth) return -1;
      if (firstSource.minWidth < secondSource.minWidth) return 1;

      return 0;
    });
  }

  return (
    <PictureStyled className={className}>
      {additionalSources.map(source => (
        <source
          key={source.key}
          srcSet={encodeURI(source.src)}
          media={`(min-width: ${source.minWidth}px)`}
        />
      ))}
      <ImgStyled src={encodeURI(src)} alt={alt} />
    </PictureStyled>
  );
};

Image.propTypes = {
  id: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  srcSet: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
    xxl: PropTypes.string,
  }),
};

Image.defaultProps = {
  alt: '',
  srcSet: null,
};

export default Image;
