import styledEmotion, { css } from 'react-emotion';
import facepaint from 'facepaint';

import { breakpoints } from 'components/Layout/variables';
import media from 'utils/mediaqueries';
import { isFunction } from 'utils/functions';

const facepaintMq = facepaint([
  media('sm'),
  media('md'),
  media('lg'),
  media('xl'),
]);

export function mq(obj) {
  return [obj.mobile, obj.tablet, obj.tabletFluid, obj.laptop, obj.desktop];
}

export function styled(Component) {
  return function(...styles) {
    return styledEmotion(Component)(
      ...styles.map(style => {
        if (isFunction(style)) {
          return props => facepaintMq(style(props));
        }
        return facepaintMq(style);
      }),
    );
  };
}

// for emotion css e.g. used for gatsby-links since we cant use styled there

export const mqCss = Object.keys(breakpoints).reduce((accumulator, label) => {
  const prefix = typeof breakpoints[label] === 'string' ? '' : 'min-width:';
  const suffix = typeof breakpoints[label] === 'string' ? '' : 'px';
  accumulator[label] = cls =>
    css`
      @media (${prefix + breakpoints[label] + suffix}) {
        ${cls};
      }
    `;
  return accumulator;
}, {});

// const paragraph = css`
//   font-size: 12px;
//   ${mqCss.medium(css`
//     font-size: 14px;
//   `)};
//   ${mqCss.large(css`
//     font-size: 16px;
//   `)};
// `
// render(
//   <p className={paragraph}>
//     This font size is different at different breakpoints.
//   </p>
// )
