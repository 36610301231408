import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, navigate } from 'gatsby';
import LoadMoreContainer from 'components/LoadMoreContainer';
import Tile from './Tile';

const Posts = props => {
  const { items } = props;

  return (
    <LoadMoreContainer limit={2}>
      {items.map(item => (
        <Tile key={item.id} onClick={to => navigate(to)} {...item} />
      ))}
    </LoadMoreContainer>
  );
};

PropTypes.propTypes = {
  items: PropTypes.array,
};

PropTypes.defaultProps = {
  items: [],
};

export default () => (
  <StaticQuery
    query={graphql`
      query PostsQuery {
        craft {
          posts: entries(section: posts, orderBy: "postDate DESC") {
            id
            title
            fullUri
            postDate
            author {
              fullName
            }
            ... on Craft_PostsPost {
              postExcerpt
              postImage {
                id
                url
                width
                height
                title
                thumbSlim: url(
                  crop: {
                    width: 990
                    height: 215
                    position: centerCenter
                    quality: 90
                  }
                )
                thumbHigh: url(
                  crop: {
                    width: 480
                    height: 600
                    position: centerCenter
                    quality: 90
                  }
                )
                thumbSquare: url(
                  crop: {
                    width: 480
                    height: 480
                    position: centerCenter
                    quality: 90
                  }
                )
              }
            }
          }
        }
      }
    `}
    render={data => <Posts items={data.craft.posts} />}
  />
);
