import React from 'react';
import PropTypes from 'prop-types';
import SwitchTheme from 'components/SwitchTheme';
import {
  TileStyled,
  LinkStyled,
  ImageStyled,
  TileInnerStyled,
  CategoryStyled,
  TitleStyled,
  PriceStyled,
} from './styles';

const Tile = ({ title, price, theme, category, link, image }) => {
  if (!link) {
    return null;
  }

  return (
    <SwitchTheme name={theme}>
      <TileStyled>
        {image?.[0] && (
          <ImageStyled
            id={image[0].id}
            alt={image[0].title}
            src={image[0].thumbSquare}
          />
        )}
        <TileInnerStyled>
          {category && <CategoryStyled>{category}</CategoryStyled>}
          {title && <TitleStyled element="h4">{title}</TitleStyled>}
          {price && <PriceStyled>{price}</PriceStyled>}
        </TileInnerStyled>
        <LinkStyled to={link.url} />
      </TileStyled>
    </SwitchTheme>
  );
};

Tile.propTypes = {};

Tile.defaultProps = {};

export default Tile;
