import { useState, useCallback } from 'react';
import Nominatim from 'nominatim-browser';

export default function useLocationGeocode() {
  const [hits, setHits] = useState([]);

  const handleRefine = useCallback(
    async input => {
      const rawHits = await Nominatim.geocode({
        q: input,
        countrycodes: 'de,ch,at,li,lu',
        featuretype: 'city,settlement',
        addressdetails: true,
        email: 'dev@dunckelfeld.de',
      });

      const newHits = rawHits.map(hit => {
        const postcode = hit.address?.postcode || '';

        const city =
          hit.address?.city ||
          hit.address?.[hit.type] ||
          hit.address?.county ||
          '';

        const state = hit.address?.state || '';

        const country = hit.address?.country_code.toUpperCase() || '';

        return {
          key: hit.place_id,
          label: `${postcode} ${city}${
            (postcode || city) && state ? ',' : ''
          } ${state} (${country})`.trim(),
          payload: hit,
        };
      });
      setHits(newHits);
    },
    [setHits],
  );

  return [hits, handleRefine];
}
