import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'components/Grid';
import Tile from 'components/Images/Tile';
import Headline from 'components/Headline';
import LinkButton from 'components/LinkButton';
import Link from 'components/Link';
import { parseAsContent } from 'utils/parser';

import { TeaserSectionStyled, RowStyled, ColumnStyled } from './styles';

export default function Teaser(props) {
  const { teaser } = props?.pageBuilder;
  const columnCount = teaser.length;
  const colWidth = `${100 / columnCount}%`;

  return (
    <TeaserSectionStyled>
      <Grid>
        <RowStyled>
          {teaser.map((item) => {
            const { content } = item?.text;

            return (
              <ColumnStyled key={`teaser-${item.id}`} width={{ sm: colWidth }}>
                <Headline element={'h4'}>{item.headline}</Headline>
                <Link to={item.teaserLink.url} target={item.teaserLink.target}>
                  <Tile columnCount={columnCount} image={item.image}></Tile>
                </Link>
                {content && <div>{parseAsContent(content)}</div>}
                {item.teaserLink && (
                  <LinkButton
                    to={item.teaserLink.url}
                    target={item.teaserLink.target}
                    themeName="grey"
                    // compact
                  >
                    {item.teaserLink.text}
                  </LinkButton>
                )}
              </ColumnStyled>
            );
          })}
        </RowStyled>
      </Grid>
    </TeaserSectionStyled>
  );
}

export const query = graphql`
  fragment PageBuilderTeaserQuery on Craft_PageBuilderTeaser {
    id
    teaser {
      id
      image {
        id
        width
        height
        title
        urlFull: url(transform: transformPostImage)
        urlLarge: url(transform: transformPostImageLarge)
        urlMedium: url(transform: transformPostImageMedium)
      }
      headline
      text {
        content
      }
      teaserLink {
        url
        target
        text
      }
    }
  }
`;
