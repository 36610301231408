import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image';
import {
  TileLinkStyled,
  ImageWrapperStyled,
  TileInnerStyled,
  CategoryStyled,
  TitleStyled,
  LabelStyled,
} from './styles';

const Tile = ({ title, category, image, provider, url }) => (
  <TileLinkStyled to={url}>
    {image?.[0] && (
      <ImageWrapperStyled>
        <Image
          id={image[0].id}
          alt={image[0].title}
          src={image[0].thumbSquare}
        />
      </ImageWrapperStyled>
    )}
    <TileInnerStyled>
      {category && <CategoryStyled>{category}</CategoryStyled>}
      {title && <TitleStyled>{title}</TitleStyled>}
      {provider && <LabelStyled>{provider}</LabelStyled>}
    </TileInnerStyled>
  </TileLinkStyled>
);

Tile.propTypes = {};

Tile.defaultProps = {};

export default Tile;
