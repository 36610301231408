export default function greatCircleDistance(_lat1, _lon1, _lat2, _lon2) {
  const r = 6371.009; // Kilometers
  const lat1 = parseFloat(_lat1, 10) * (Math.PI / 180);
  const lon1 = parseFloat(_lon1, 10) * (Math.PI / 180);
  const lat2 = parseFloat(_lat2, 10) * (Math.PI / 180);
  const lon2 = parseFloat(_lon2, 10) * (Math.PI / 180);
  const lonDelta = lon2 - lon1;
  const a =
    (Math.cos(lat2) * Math.sin(lonDelta)) ** 2 +
    (Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lonDelta)) **
      2;
  const b =
    Math.sin(lat1) * Math.sin(lat2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.cos(lonDelta);
  const angle = Math.atan2(Math.sqrt(a), b);

  return angle * r;
}
