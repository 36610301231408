import styled from 'react-emotion';
import Headline from 'components/Headline';
import Image from 'components/Image';
import Link from 'components/Link';
import media from 'utils/mediaqueries';
import { colors, sizes } from 'components/Layout/variables';

/**
 * Tile.
 */
export const TileStyled = styled('div')(
  {
    position: 'relative',
    backgroundColor: colors.white,
    marginBottom: sizes.spacingHalf,
    borderRadius: sizes.radius,
    width: '100%',
    display: 'flex',

    '&::before': {
      content: '""',
      width: 1,
      height: 0,
      marginLeft: -1,
      float: 'left',
      paddingTop: '100%',
    },

    '&::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },

    [media('xl')]: {
      display: 'block',
    },
  },
  props => ({
    backgroundColor: props.theme.backgroundPrimary,
  }),
);

/**
 * Link.
 */
export const LinkStyled = styled(Link)({
  position: 'absolute',
  display: 'block',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,

  '&:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});

/**
 * Image.
 */
export const ImageStyled = styled(Image)({
  position: 'absolute',
  boxSizing: 'border-box',
  width: '80%',
  height: '80%',
  margin: '10%',
  top: 0,
  left: 0,

  img: {
    position: 'absolute',
    left: '50%',
    maxHeight: '100%',
    top: '0',
    transform: 'translate(-50%,0)',
    boxShadow: '0 20px 56px rgba(0,0,0,0.1)',

    [media('xl')]: {
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
});

/**
 * Inner tile.
 */
export const TileInnerStyled = styled('div')({
  position: 'relative',
  padding: `${sizes.spacingHalf} ${sizes.spacingQuarters}`,
  backgroundColor: 'rgba(255,255,255,.6)',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  boxSizing: 'border-box',
  display: 'flex',
  alignSelf: 'flex-end',
  width: '100%',
  height: 'auto',

  // Show product info on hover
  [media('xl')]: {
    top: 0,
    bottom: 'auto',
    padding: sizes.spacing,
    display: 'flex',
    opacity: '0',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    transition: '.25s ease opacity',

    [`${TileStyled}:hover &`]: {
      opacity: '1',
    },
  },
});

export const CategoryStyled = styled('span')({
  fontWeight: 'bold',
  fontSize: '.8em',
  color: colors.grey,
  opacity: 0.5,
  marginRight: sizes.spacingDouble,
});

export const TitleStyled = styled(Headline)({
  width: '100%',
  flex: 'none',
  overflow: 'hidden',
  lineHeight: 1.2,
  fontSize: 18,
  margin: `4px 0 ${sizes.spacingQuarter}`,

  [media('sm')]: {
    marginTop: 0,
    marginBottom: sizes.spacingHalf,
  },

  [media('xl')]: {
    fontSize: 24,
  },
});

export const PriceStyled = styled('span')({
  color: colors.green,
  fontWeight: 'bold',
});
