import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { groupItemsByDate, filterItemsFromToday } from 'utils/date';
import LoadMoreContainer from 'components/LoadMoreContainer';
import Tile from './Tile';
import useLocationFilteredEvents from './InputAutosuggestLocation/useLocationFilteredEvents';

export const Events = ({ items }) => {
  // 1. only items after today
  const itemsFromToday = filterItemsFromToday(items, 'eventDate');

  // 2. filter and sort for loaction filter
  const [itemsFilteredByLocation, locationFilter] = useLocationFilteredEvents(
    itemsFromToday,
  );

  // 3. group by date
  const itemsGroupedByDate = groupItemsByDate(
    itemsFilteredByLocation,
    'year',
    'eventDate',
  );

  // 4. Make tiles / prepare events by prepending headline of group.
  const events = [];
  Object.keys(itemsGroupedByDate).forEach(year => {
    itemsGroupedByDate[year].items.forEach((item, index) => {
      events.push(
        <Tile key={item.id} headline={index === 0 ? year : false} {...item} />,
      );
    });
  });

  return (
    <>
      {locationFilter}
      <LoadMoreContainer limit={4}>
        {events.length > 0 ? events : <p>Keine Veranstaltungen</p>}
      </LoadMoreContainer>
    </>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query EventsQuery {
        craft {
          events: entries(section: events, orderBy: "eventDate ASC") {
            id
            ... on Craft_EventsEvent {
              eventDate
              eventVenue {
                id
                title
                eventVenueLink {
                  target
                  url
                }
                eventVenueAddress {
                  zip
                  city
                  street1
                  country
                  lat
                  lng
                }
              }
              eventTicketLink {
                url
              }
              eventTicketAvailability
              eventProgram {
                title
              }
            }
          }
        }
      }
    `}
    render={data => <Events items={data.craft.events} />}
  />
);
