import styled from 'react-emotion';
import { colors } from 'components/Layout/variables';

/**
 * Text input
 */
export const InputStyled = styled('input')({
  width: 300,
  maxWidth: '100%',
  height: 'auto',
  display: 'inline-block',
  border: 'none',
  borderBottom: '2px solid transparent',
  borderRadius: 0,
  WebkitAppearance: 'none',
  backgroundColor: 'transparent',
  font: 'inherit',
  transition: 'border 50ms ease-in-out',
  fontWeight: 700,
  color: 'inherit',

  '&::placeholder': {
    transition: 'color 50ms ease-in-out',
  },

  '&:not(:focus)::placeholder': {
    color: 'inherit',
  },

  '&:focus': {
    borderBottom: `2px solid ${colors.black}`,
    outline: 'none',
  },
});
