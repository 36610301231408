import React from 'react';
import { graphql } from 'gatsby';
import ImagesDefault from 'components/Images';

export default function Images(props) {
  const { images } = props?.pageBuilder;

  return <ImagesDefault images={images} />;
}

export const query = graphql`
  fragment PageBuilderImagesQuery on Craft_PageBuilderImages {
    images: imagesList {
      id
      imageTitle
      image {
        id
        width
        height
        title
        urlFull: url(transform: transformPostImage)
        urlLarge: url(transform: transformPostImageLarge)
        urlMedium: url(transform: transformPostImageMedium)
      }
    }
  }
`;
