import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid, Row, Column } from 'components/Grid';
import LoadMoreContainer from 'components/LoadMoreContainer';
import Tile from './Tile';
import { VideosStyled, VideoItemStyled } from './styles';

class Videos extends Component {
  render() {
    const { items } = this.props;

    return (
      <VideosStyled>
        <Grid>
          <Row>
            <LoadMoreContainer limit={3}>
              {items.map(item => (
                <Column width={{ sm: '50%', lg: '33.33%' }} key={item.id}>
                  <VideoItemStyled>
                    <Tile {...item} />
                  </VideoItemStyled>
                </Column>
              ))}
            </LoadMoreContainer>
          </Row>
        </Grid>
      </VideosStyled>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query VideosQuery {
        craft {
          items: entries(section: videos) {
            id
            title
            ... on Craft_VideosVideo {
              url: videoLink
              category: videoCategory
              provider: videoProvider
              image: videoPreviewImage {
                id
                width
                height
                title
                url
                thumbSquare: url(transform: transformVideoThumb)
              }
            }
          }
        }
      }
    `}
    render={data => <Videos items={data.craft.items} />}
  />
);
