import React from 'react';
import { Grid, Column } from 'components/Grid';
import Tile from './Tile';
import { ImagesStyled, RowStyled } from './styles';

export default function Images(props) {
  const images = props?.images;

  if (!images?.length) return null;

  const columnCount = images.length;
  const imgColWidth = `${100 / columnCount}%`;

  return (
    <ImagesStyled>
      <Grid>
        <RowStyled>
          {images.map(item => (
            <Column width={{ sm: imgColWidth }} key={`image-${item.id}`}>
              <Tile columnCount={columnCount} {...item} />
            </Column>
          ))}
        </RowStyled>
      </Grid>
    </ImagesStyled>
  );
}
