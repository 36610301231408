import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import { colors, fonts, sizes } from 'components/Layout/variables';

/**
 * Images.
 */
export const ImagesStyled = styled('div')({
  width: '100%',
  height: 'auto',
  display: 'block',
  marginBottom: sizes.spacingHalfDouble,
});

/**
 * Image.
 */
export const ImageWrapperStyled = styled('div')({
  position: 'relative',
  marginTop: sizes.spacing,
});

export const ImageStyled = styled('picture')(
  {
    display: 'block',
    textAlign: 'center',
  },
  props => ({
    img: {
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: props.maxHeight,
      margin: '0 auto',
    },
  }),
);

export const ImageTitle = styled('div')({
  fontSize: fonts.sizes.smaller,
  fontWeight: 'bold',
  color: colors.greyLightAlt,
  padding: `0 ${sizes.spacingHalf}`,
  textAlign: 'center',
});
