import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import { sizes } from 'components/Layout/variables';

export const HeroStyled = styled('section')(
  {
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundClip: 'content-box',
    color: 'white',
  },
  (props) => ({
    backgroundColor: props.theme.backgroundPrimary,
    minHeight: '75vh',

    [media('md')]: {
      minHeight: 700,
      height: `calc(100vh - ${props.theme.spacingDouble})`,
    },
  }),
);

export const ContentStyled = styled('div')({
  position: 'relative',
  maxWidth: '780px',
  fontWeight: '500',
  color: 'white',
});

export const ContentTextStyled = styled('div')(
  {
    width: '60vw',
    margin: '0 0 0 10%',
  },

  (props) => ({
    p: {
      marginBottom: props.theme.spacing,
    },

    [media('md')]: {
      width: '40vw',

      p: {
        marginBottom: props.theme.spacingDouble,
      },
    },
  }),
);

export const BgImgWrapper = styled('div')(
  {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: '0',
    left: '0',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // opacity: '0.3',
    '&:after': {
      content: '" "',
      width: '100%',
      height: '100%',
      position: 'absolute',
      background: 'linear-gradient(rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 30% )',
      zIndex: 0,
      display: 'block',
    },

    [media(0, 'md')]: {
      '&:after': {
        background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.2))',
      },
    },
  },
  (props) => ({
    backgroundImage: props.backgroundImage && `url('${props.backgroundImage}')`,
  }),
  // (props) =>
  // props.hasSpacing && {
  // width: `calc(100% + ${props.theme.spacingHalf})`,
  // margin: `-${props.theme.spacingQuarter}`,

  // [media('sm')]: {
  // width: `calc(100% + ${props.theme.spacingHalf})`,
  // margin: `-${props.theme.spacingQuarter}`,
  // },

  // [media('md')]: {
  // width: `calc(100% + ${props.theme.spacingDouble})`,
  // margin: `-${props.theme.spacing}`,
  // },
  // },
);

export const ImgWrapper = styled('div')({
  maxWidth: '80%',
  margin: '0 auto 25px',

  [media('sm')]: {
    maxWidth: '260px',
    margin: '0 0 0 6%',
  },

  [media('md')]: {
    maxWidth: 360,
    margin: '0 0 0 6%',
  },

  [media('lg')]: {
    maxWidth: 460,
    margin: '0 0 0 6%',
  },

  [media('xxl')]: {
    maxWidth: '658px',
    margin: '0 0 0 6%',
  },
});
