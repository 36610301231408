import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import LinkButton from 'components/LinkButton';
import { LoadMoreButtonWrapperStyled } from './styles';

class LoadMoreContainer extends Component {
  /**
   * Initial state.
   */
  state = {
    loadMoreClicked: false,
  };

  /**
   * Function:
   * Load more on button click.
   */
  handleLoadMore = () => {
    this.setState({
      loadMoreClicked: true,
    });
  };

  render() {
    const { children, limit } = this.props;
    const { loadMoreClicked } = this.state;

    // Limit number of items to show.
    const childrenArray = Children.toArray(children);
    const childrenLimited = loadMoreClicked
      ? childrenArray
      : childrenArray.slice(0, limit);

    return (
      <>
        {childrenLimited}
        {!loadMoreClicked && children.length > limit && (
          <LoadMoreButtonWrapperStyled>
            <LinkButton onClick={this.handleLoadMore} iconName="dots" compact>
              Mehr anzeigen
            </LinkButton>
          </LoadMoreButtonWrapperStyled>
        )}
      </>
    );
  }
}

LoadMoreContainer.propTypes = {
  children: PropTypes.node.isRequired,
  limit: PropTypes.number,
};

LoadMoreContainer.defaultProps = {
  limit: 4,
};

export default LoadMoreContainer;
