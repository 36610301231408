import styled from 'react-emotion';
import Headline from 'components/Headline';
import media from 'utils/mediaqueries';
import { colors, sizes } from 'components/Layout/variables';

/**
 * Tile.
 */
export const TileStyled = styled('div')({
  backgroundColor: colors.white,
  marginBottom: sizes.spacingHalf,
  borderRadius: sizes.radius,
  cursor: 'pointer',

  '&:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },

  // Thumbnail: high
  [media('xl')]: {
    display: 'flex',
    height: 300,
  },

  // Thumbnail: square
  [media('xxl')]: {
    height: 240,
  },
});

/**
 * Image.
 */
export const ImageWrapperStyled = styled('div')({
  flex: 'none',
  position: 'relative',
  borderRadius: sizes.radius,
  overflow: 'hidden',
  width: '100%',

  // Thumbnail: high
  [media('xl')]: {
    width: 240,
  },

  // Thumbnail: square
  [media('xxl')]: {
    width: 240,
  },
});

/**
 * Inner tile.
 */
export const TileInnerStyled = styled('div')({
  position: 'relative',
  padding: `${sizes.spacingQuarters} ${sizes.spacingQuarters} 0`,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 8,

  [media('sm')]: {
    padding: `${sizes.spacing} ${sizes.spacing} 0`,
  },

  [media('md')]: {
    padding: `${sizes.spacing} ${sizes.spacingDouble} 0`,
  },

  [media('xl')]: {
    padding: `${sizes.spacing} ${sizes.spacingDoubleQuarter} 0`,
  },
});

export const MetaWrapperStyled = styled('div')({});

export const MetaStyled = styled('span')({
  fontWeight: 'bold',
  fontSize: '.8em',
  color: colors.greyLightAlt,
  marginRight: sizes.spacingDouble,
});

export const DateStyled = styled('span')({});

export const TitleStyled = styled(Headline)({
  marginTop: sizes.spacingQuarter,
  width: '100%',
  flex: 'none',
  overflow: 'hidden',
  lineHeight: 1.2,

  [media('sm')]: {
    marginTop: sizes.spacingHalf,
    marginBottom: sizes.spacingQuarters,
  },
});

export const ExcerptWrapperStyled = styled('div')({
  flex: 1,
  position: 'relative',
  maxHeight: 160,
  minHeight: 80,
  overflow: 'hidden',

  // Fading out inner items
  '&::after': {
    content: '""',
    height: '100px',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    left: '0',
    right: '0',
    background:
      'linear-gradient(rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 85%)',
  },
});
