import React from 'react';
import { graphql } from 'gatsby';
import LinkButton from 'components/LinkButton';
import Icon from 'components/Icon';

export default function Button(props) {
  const { buttonLink, buttonIcon } = props?.pageBuilder;

  return (
    <LinkButton
      to={buttonLink.url}
      target={buttonLink.target}
      iconName={buttonIcon}
    >
      {buttonLink.text}
    </LinkButton>
  );
}

export const query = graphql`
  fragment PageBuilderButtonQuery on Craft_PageBuilderButton {
    id
    buttonIcon
    buttonLink {
      url
      target
      text
    }
  }
`;
