import React from 'react';
import moment from 'moment';
import 'moment/locale/de';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import Image from 'components/Image';
import {
  TileStyled,
  ImageWrapperStyled,
  TileInnerStyled,
  MetaWrapperStyled,
  MetaStyled,
  TitleStyled,
  ExcerptWrapperStyled,
} from './styles';

const Tile = ({
  fullUri,
  title,
  author,
  postDate,
  postExcerpt,
  postImage,
  onClick,
}) => {
  // Format date values.
  const postDateFormatted = moment
    .unix(postDate)
    .locale('de')
    .format('D. MMM YYYY');

  return (
    <TileStyled onClick={() => onClick(fullUri)}>
      {postImage &&
        postImage.map(image => (
          <ImageWrapperStyled key={image.id}>
            <Image
              id={image.id}
              alt={image.title}
              src={image.thumbSlim}
              srcSet={{ xl: image.thumbHigh, xxl: image.thumbSquare }}
            />
          </ImageWrapperStyled>
        ))}
      <TileInnerStyled>
        {title && <TitleStyled element="h3">{title}</TitleStyled>}
        {postExcerpt && (
          <ExcerptWrapperStyled>
            <Text element="span">{postExcerpt}</Text>
          </ExcerptWrapperStyled>
        )}
      </TileInnerStyled>
    </TileStyled>
  );
};

Tile.propTypes = {
  fullUri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  postDate: PropTypes.number.isRequired,
  postExcerpt: PropTypes.string,
};

Tile.defaultProps = {
  postExcerpt: '',
  author: {
    fullName: 'Hazel Brugger',
  },
};

export default Tile;
