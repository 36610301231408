import React, { Component } from 'react';
import { graphql } from 'gatsby';
import * as pageBuilderComponents from 'components/Craft/PageBuilderComponents';

const componentNamePattern = 'Craft_PageBuilder';

function getComponent(__typename) {
  const componentName = __typename.replace(componentNamePattern, '');
  return pageBuilderComponents[componentName] || false;
}

export default class PageBuilder extends Component {
  render() {
    const { moduleSlug, pageBuilder } = this.props;

    return pageBuilder.map((el, index) => {
      const PageBuilderComponent = getComponent(el.__typename);
      const key = `${moduleSlug}-${el.id}-${index}`;

      return PageBuilderComponent ? (
        <PageBuilderComponent pageBuilder={el} key={key} />
      ) : null;
    });
  }
}

export const query = graphql`
  fragment PageBuilderQuery on Craft_PageBuilderUnion {
    __typename

    ... on Craft_PageBuilderHero {
      ...PageBuilderHeroQuery
    }

    ... on Craft_PageBuilderHeadline {
      ...PageBuilderHeadlineQuery
    }

    ... on Craft_PageBuilderText {
      ...PageBuilderTextQuery
    }
    ... on Craft_PageBuilderTeaser {
      ...PageBuilderTeaserQuery
    }

    ... on Craft_PageBuilderBlockquote {
      ...PageBuilderBlockquoteQuery
    }

    ... on Craft_PageBuilderButton {
      ...PageBuilderButtonQuery
    }

    ... on Craft_PageBuilderImages {
      ...PageBuilderImagesQuery
    }

    ... on Craft_PageBuilderComponent {
      ...PageBuilderComponentQuery
    }
  }
`;
