import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Headline from 'components/Headline';
import Availability, { getAvailabilityForKey } from './Availability';
import Venue from './Venue';
import {
  TileStyled,
  WrapperLeftStyled,
  WrapperMiddleStyled,
  WrapperRightStyled,
  WrapperDetailsStyled,
  DateStyled,
  DayStyled,
  MonthStyled,
  TimeStyled,
  TitleStyled,
  TicketButtonStyled,
} from './styles';

const Tile = (props) => {
  const {
    headline,
    eventDate,
    eventVenue,
    eventTicketLink,
    eventTicketAvailability,
    eventProgram,
    displayDistance,
  } = props;

  // Format date values.
  const eventDateMoment = moment.unix(eventDate);
  const eventDateFormatted = eventDateMoment.format('YYYY-MM-DD @ HH:mm');
  const eventDateMonth = eventDateMoment.format('MMM');
  const eventDateDay = eventDateMoment.format('DD');
  const eventDateTime = eventDateMoment.format('HH:mm');
  const [availability, setAvailability] = useState({ color: '#c0c0c0' });

  useEffect(() => {
    const avail = getAvailabilityForKey(eventTicketAvailability);
    setAvailability(avail);
  }, []);

  const color = availability.color;

  console.log({
    availability,
    color,
    eventTicketAvailability,
    eventVenue,
    headline,
  });

  return (
    <>
      {headline && <Headline element="h5">{headline}</Headline>}
      <TileStyled
        hightlightColor={availability.color}
        style={{ borderColor: availability.color }}
      >
        <WrapperLeftStyled>
          <WrapperDetailsStyled>
            {eventDate && (
              <DateStyled title={eventDateFormatted}>
                <DayStyled>{eventDateDay}</DayStyled>
                <MonthStyled>{eventDateMonth}</MonthStyled>
                <TimeStyled>{eventDateTime}</TimeStyled>
              </DateStyled>
            )}

            <div>
              <TitleStyled>
                {eventProgram.map((program) => program.title).join(', ')}
              </TitleStyled>
              <Availability status={eventTicketAvailability} small />
              <small style={{ opacity: 0.66 }}>
                <strong>
                  {displayDistance ? <> —&nbsp;{displayDistance}</> : null}
                </strong>
              </small>
            </div>
          </WrapperDetailsStyled>
        </WrapperLeftStyled>

        <WrapperMiddleStyled>
          {eventVenue.map((venue) => (
            <Venue key={`venue-${venue.id}`} {...venue} />
          ))}
        </WrapperMiddleStyled>

        <WrapperRightStyled>
          {eventTicketAvailability !== 'soldOut' && eventTicketLink?.url ? (
            <TicketButtonStyled to={eventTicketLink.url} themeName="grey">
              Tickets
            </TicketButtonStyled>
          ) : (
            <Availability status={eventTicketAvailability} uppercase />
          )}
        </WrapperRightStyled>
      </TileStyled>
    </>
  );
};

Tile.propTypes = {
  id: PropTypes.number.isRequired,
  eventDate: PropTypes.string.isRequired,
  eventVenue: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      eventVenueAddress: PropTypes.shape({
        city: PropTypes.string,
      }),
    }),
  ),
  eventTicketLink: PropTypes.shape({
    url: PropTypes.string,
  }),
  eventTicketAvailability: PropTypes.string,
  eventProgram: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

Tile.defaultProps = {
  eventTicketLink: null,
  eventTicketAvailability: null,
};

export default Tile;
