import React from 'react';
import { graphql } from 'gatsby';
import Events from 'components/Events';
import Posts from 'components/Posts';
import Shop from 'components/Shop';
import Videos from 'components/Videos';

const Component = ({ pageBuilder }) => {
  const { componentName } = pageBuilder;

  switch (componentName) {
    case 'events':
      return <Events />;
    case 'blog':
      return <Posts />;
    case 'shop':
      return <Shop />;
    case 'videos':
      return <Videos />;
    default:
      return null;
  }
};

export const query = graphql`
  fragment PageBuilderComponentQuery on Craft_PageBuilderComponent {
    id
    componentName
  }
`;

export default Component;
